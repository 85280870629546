import WebComponent from '../webcomponent.js'
import "../components/item-rating.js"
import "../components/buy-organisations.js"
import "../components/login-banner.js"

// Colours from https://jumptrak.io/catalogue/
const colors = [
  "#6EC1E4", "#54595F", "#7A7A7A", "#61CE70", "#002945", "#41B7C3",
]

const template = (obj) => html`
<link rel="stylesheet" href="/css/app.css">
<style>
#booklist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-type-icon {
  width: 44px;
  height: 44px;
  margin-right: 1em;
  fill: #0a4059;
}

ion-card-title, ion-card-subtitle {
  color: #002945;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bookImage {
  width: 100%;
  background: #a3c8e3;
  display: flex;
  justify-content: center
}

.bookCard ion-card-header, .bookCard .bookImage {
  cursor: pointer;
}

.searchContainer {
  width: 100%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-top: 0;
}

.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #ccc;
}

.collapsible:after {
  content: '\\02795';
  font-size: 13px;
  color: white;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\\2796";
}
</style>
<script>

</script>
<button type="button" class="collapsible">Store Search</button>
<ion-card class="searchContainer">
<ion-grid>
  <ion-row wrap>
    <ion-col size="12" size-md="4"><ion-input fill="outline" name="bookname" label="Product Name" label-placement="floating" placeholder="Product Name"></ion-input></ion-col>
    <ion-col size="12" size-md="4">
      <ion-select fill="outline" name="productTypes" aria-label="Product Type" placeholder="Product Type" multiple="true">
      ${renderProductTypeList(obj)}
      </ion-select>
    </ion-col>
    <ion-col size="12" size-md="4">
      <ion-select fill="outline" name="publisher" aria-label="Publisher" placeholder="Publisher" multiple="true">
        ${renderPublisherList(obj)}
      </ion-select>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid>
  <ion-row wrap>
    <ion-col size="12" size-md="4">
      <ion-select fill="outline" name="curriculum" aria-label="Curriculum" placeholder="Curriculum">
        ${renderCurriculumList(obj)}
      </ion-select>
    </ion-col>
    <ion-col  size="12" size-md="4">
      <ion-select fill="outline" name="bookType" aria-label="Book Type" placeholder="Book Type">
      ${renderBookTypeList(obj)}
      </ion-select>
    </ion-col>
    <!-- ion-col  size="12" size-md="3">
      <ion-select fill="outline" name="rating" aria-label="Rating" placeholder="Rating">
        <ion-select-option value="1">1</ion-select-option>
        <ion-select-option value="2">2</ion-select-option>
        <ion-select-option value="3">3</ion-select-option>
        <ion-select-option value="4">4</ion-select-option>
        <ion-select-option value="5">5</ion-select-option>
      </ion-select>
    </ion-col -->
    <ion-col  size="12" size-md="4">
      <ion-select fill="outline" name="grade" aria-label="Grade/Year" placeholder="Grade/Year" multiple="true">
        <ion-select-option value="1">1</ion-select-option>
        <ion-select-option value="2">2</ion-select-option>
        <ion-select-option value="3">3</ion-select-option>
        <ion-select-option value="4">4</ion-select-option>
        <ion-select-option value="5">5</ion-select-option>
        <ion-select-option value="6">6</ion-select-option>
        <ion-select-option value="7">7</ion-select-option>
        <ion-select-option value="8">8</ion-select-option>
        <ion-select-option value="9">9</ion-select-option>
        <ion-select-option value="10">10</ion-select-option>
        <ion-select-option value="11">11</ion-select-option>
        <ion-select-option value="12">12</ion-select-option>
        <ion-select-option value="13">13</ion-select-option>
        <ion-select-option value="14">14</ion-select-option>
        <ion-select-option value="15">15</ion-select-option>
        <ion-select-option value="16">16</ion-select-option>
        <ion-select-option value="17">17</ion-select-option>
        <ion-select-option value="18">18</ion-select-option>
      </ion-select>
    </ion-col>
  </ion-row>
</ion-grid>
<p style="display: flex; flex-direction: row; justify-content: center">
  <ion-button name="clearSearch" style="color: #000; --background: #ccc; margin-right: 1em;">Clear</ion-button>
  <ion-button name="searchButton" style="--background: #1c8644;">Search</ion-button>
</p>
</ion-card>

<header id="login" style="display: none">
  <login-banner></login-banner>
</header>

<buy-organisations></buy-organisations>

<div id="booklist"></div>

<ion-button id="btnLoadMore" style="display:none">Load more</ion-button>

<app-dialog id="dlgBookDetails"></app-dialog>

<p>&nbsp;</p>
`

const renderBookList = (obj) => html`
  ${obj.validProducts().reduce((acc, book, i) => acc + renderBook(book, obj), '')}
  ${obj.validProducts().length == 0 ? "<p>No products found.</p>" : ""}
`

const renderProductTypeList = (obj) => html`
  ${obj && obj.productTypes && obj.productTypes.length && obj.productTypes.map((productType) => renderProductType(productType))}
`

const renderProductType = (prod) => html`
    <ion-select-option value="${prod.ruid}">${prod.name}</ion-select-option>
`

const renderBookTypeList = (obj) => html`
  ${obj && obj.subTypes && obj.subTypes.length && obj.subTypes.map((bookType) => renderBookType(bookType))}
`

const renderBookType = (bType) => html`
    <ion-select-option value="${bType}">${bType}</ion-select-option>
`

const renderPublisherList = (obj) => html`
  ${obj && obj.parties && obj.parties.length && obj.parties.map((publisher) => renderPublisher(publisher))}
`

const renderPublisher = (pub) => html`
    <ion-select-option value="${pub.ruid}">${pub.name}</ion-select-option>
`

const renderCurriculumList = (obj) => html`
  ${obj && obj.curricula && obj.curricula.length && obj.curricula.map((curr) => renderCurriculum(curr))}
`

const renderCurriculum = (curr) => html`
    <ion-select-option value="${curr.ruid}">${curr.name}</ion-select-option>
`

const renderBookDetails = (obj, book, skipTrialButton = false) => html`
  <b>${sanitize(book.description)}</b>
  <p>
    <img style="max-width: 400px; max-height: 400px;" src="${obj.getImage(book, 'main')}">
    <img style="max-width: 400px; max-height: 400px;" src="${obj.getImage(book, 'back')}">
  </p>
  <b>Type:</b> ${book.productTypeName}<br/>
  <b>Updated:</b> ${obj.formatDateTime(book.lastUpdateTime)}<br/>
  <details id="bulkPricing"><summary>Bulk discounted pricing</summary>
    <ion-skeleton-text animated="true"></ion-skeleton-text>
  </details>
  <p>${sanitize(book.about) || "No description available"}</p>
  ${book.isBundle ? `
    <p><b>Bundle contents:</b>
      ${book.childProductCapabilities.map(c =>
  html`<details productIdentifier="${c.productIdentifier}" capabilityCode="${c.name.split(":").pop()}"><summary>${c.name} <ion-chip>x${c.quantity}</ion-chip></summary>
        <p>
          <ion-skeleton-text animated="true"></ion-skeleton-text>
        </p>
        </details>`).join("")}
    </p>
  ` : ""}
  ${book.toc && book.toc.children ? `
    <p>
    <b>Table of contents:</b><br/>
    ${renderToc(book.toc.children)}
    </p>
  `: ""}
  ${book.terms ? `
    <p>
    <b>Terms of use:</b><br/><br/>
    ${sanitize(book.terms)}
    </p>
  `
    : ""}
  ${book.allowedTempDays > 0 && !skipTrialButton ? html`
    <p>
        <ion-button id="btnTrial" title="Try this product for a limited time">${book.allowedTempDays} days trial</ion-button>
    </p>` : ``}
`

const renderToc = (tocChildren) => (tocChildren && tocChildren.length > 0 ?
  html`<ol>
  ${tocChildren.map((c) => `
      <li>${sanitize(c.data.name)}</li>
      ${renderToc(c.children)}
  `).join("")}
  </ol>`
  : "")

const renderBook = (book, obj) => html`
<ion-card style="width: 340px;" class="bookCard" bookId="${book.forProduct}">
  <div class="bookImage">
  <img id="bannerImage${book.id}" src="${obj.getImage(book, 'front')}" alt="cover" align="center" style="height: 140px; width: 340px; object-fit: contain"/>
  </div>
  <ion-card-header style="display:flex; flex-direction: row">
    <img title="${book.productTypeName}" alt="${book.productTypeName}" src="/images/${obj.bookIcon(book.productTypeName)}" class="product-type-icon"></img>
    <div style="display:flex; flex-direction: column; overflow: auto">
      <ion-card-title title="${book.description}">${sanitize(book.description)}</ion-card-title>
      <ion-card-subtitle title="${book.ownerParty.name}">${sanitize(book.ownerParty.name)}</ion-card-subtitle>
    </div>
  </ion-card-header>

  <ion-card-content>
    <!-- b>Rating:</b> <item-rating rating="${book.rating}" reviews="${book.reviewCount}"></item-rating><br/ -->
    <h1 style="text-align: end; padding-right: 0.1em; color: #002945; text-decoration: bold">${obj.priceFormatted(book.price.amount)}</h1>
    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <ion-input id="quantity-${book.id}" type="number" name="quantity" title="Quantity" value="1" min="1" max="1000" style="max-width: 10em"></ion-input>
      <ion-button name="addToCart" product="${book.id}" style="--background: #1c8644;">Add to cart</ion-button>
    </div>
  </ion-card-content>
</ion-card>
`

const renderBulkPricing = (obj, prices) => html`
<table>
  <thead>
    <th>Minimum quantity</th>
    <th>Price</th>
  </thead>
  <tbody>
    ${prices.prices.map(price => `
    <tr>
      <td>${price.minimumQuantity}</td>
      <td align="right">${obj.priceFormatted(price.amount)}</td>
    </tr>`).join("")}
  </tbody>
</table>
`

customElements.define("page-product-list", class extends WebComponent {
  async init() {
    app.autohideProgress = false
    Promise.all([
      app.api.getProductTypes().then((res) => this.productTypes = res),
      app.api.getSubTypes().then((res) => this.bookTypes = res),
      app.api.getPublishers().then((res) => this.publishers = res),
      app.api.getCurriculum().then((res) => this.curriculum = res),
    ]).then((res) => this.renderSearchDropdowns())
  }

  update() {
    this.searchPage = 1;
    this.performSearch()
  }

  // callback from App when currency changes
  onCurrency(currencyCode) {
    this.update()
  }

  async render() {
    this.shadow.innerHTML = template(this);
    this.dlgBookDetails = this.shadow.querySelector("#dlgBookDetails")
    this.shadow.querySelector("#login").style.display = (app.isLoggedIn() ? "none" : "block");
    var coll = this.shadow.querySelector(".collapsible");

    coll.addEventListener("click", () => {
      coll.classList.toggle("active");
      var searchContainer = this.shadow.querySelector(".searchContainer");
      if (searchContainer.style.maxHeight) {
        searchContainer.style.maxHeight = null;
      } else {
        searchContainer.style.maxHeight = content.scrollHeight + "px";
      }
    });

    this.registerButtons()

    this.searchButton = this.shadow.querySelector("ion-button[name=searchButton]")
    this.searchButton.addEventListener('click', () => {
      this.searchPage = 1
      this.performSearch();
    })
    this.searchButton.click()

    this.clearSearch = this.shadow.querySelector("ion-button[name=clearSearch]")
    this.clearSearch.addEventListener('click', () => {
      // reset all the search input fields
      let selects = this.shadow.querySelectorAll(".searchContainer ion-select")
      for (let sel of selects) sel.value = ""
      let inputs = this.shadow.querySelectorAll(".searchContainer ion-input")
      for (let inp of inputs) inp.value = ""
    })

    this.shadow.querySelector("buy-organisations").addEventListener('change', () => {
      // re-run the search for the new organisation
      this.searchPage = 1
      this.performSearch();
    })
  }

  renderSearchDropdowns() {
    this.shadow.querySelector("ion-select[name=productTypes]").innerHTML = renderProductTypeList(this.productTypes);
    this.shadow.querySelector("ion-select[name=publisher]").innerHTML = renderPublisherList(this.publishers);
    this.shadow.querySelector("ion-select[name=curriculum]").innerHTML = renderCurriculumList(this.curriculum);
    this.shadow.querySelector("ion-select[name=bookType]").innerHTML = renderBookTypeList(this.bookTypes);
  }

  async renderChildProduct(details) {
    if (details.loaded) return; // already loaded
    let productIdentifier = details.attributes['productIdentifier'].value;
    let capabilityCode = details.attributes['capabilityCode'].value;
    if (!productIdentifier) return;
    if (!capabilityCode) capabilityCode = "use";
    let bookDetails = this.products.filter((p) => p.forProduct == productIdentifier)[0]
    if (!bookDetails) {
      let res = await app.api.getProductCapability(productIdentifier, capabilityCode, false)
      bookDetails = res.product
    }
    details.children[1].innerHTML = renderBookDetails(this, bookDetails, true)
    details.loaded = true;
  }

  async performSearch() {
    let selectedProduct = this.shadow.querySelector("ion-select[name=productTypes]");
    let searchText = this.shadow.querySelector("ion-input[name=bookname]");
    let selectedPublisher = this.shadow.querySelector("ion-select[name=publisher]");
    let selectedCurriculum = this.shadow.querySelector("ion-select[name=curriculum]");
    let bookType = this.shadow.querySelector("ion-select[name=bookType]");
    let selectedGrades = this.shadow.querySelector("ion-select[name=grade]");
    //let selectedRating = this.shadow.querySelector("ion-select[name=rating]");
    //console.log(selectedProduct.value, searchText.value, selectedPublisher.value, selectedCurriculum.value)
    //console.log(bookType.value, parseInt(selectedRating.value), selectedGrades.value.reduce( (acc, x ) => acc.concat(+x), []))
    // TODO: cache by auth token and buy org, filtering can be client-side (with "Load more...")
    let questionsAPIData = await app.api.getStoreBooks(
      searchText.value,
      selectedProduct.value,
      selectedPublisher.value,
      0,
      bookType.value,
      selectedCurriculum.value,
      selectedGrades.value ? selectedGrades.value.map(v => Number(v)) : [],
      app.getBuyOrganisation(),
      app.currency.currencyCode(),
      this.searchPage,
    );


    if (questionsAPIData.productCapabilities.length > 20) {
      this.btnLoadMore.style.display = "block"
    }

    if (this.searchPage > 1) {
      let products = this.products.concat(questionsAPIData.productCapabilities)
      this.products = products
    } else {
      this.products = questionsAPIData.productCapabilities
    }

    this.shadow.querySelector("#booklist").innerHTML = renderBookList(this)

    requestAnimationFrame(() => {
      this.registerButtons()
      app.autohideProgress = true
      app.progress.hide()
    })

  }

  registerButtons() {
    for (let btn of this.shadow.querySelectorAll("ion-button[name=addToCart]")) {
      btn.addEventListener('click', () => {
        let product = this.products.filter(p => p.id == btn.attributes['product'].value)[0]
        let quantity = this.shadow.querySelector(`#quantity-${product.id}`)
        app.cart.addProduct(product, quantity.value)
        app.toast("Item added to cart, click the cart button top-right to view the cart.")
      })
    }

    for (let card of this.shadow.querySelectorAll("ion-card.bookCard")) {
      card.querySelector(".bookImage").addEventListener('click', () => this.showBookDetails(card.getAttribute("bookId")))
      card.querySelector("ion-card-header").addEventListener('click', () => this.showBookDetails(card.getAttribute("bookId")))
    }

    this.btnLoadMore = this.shadow.querySelector("#btnLoadMore")
    this.btnLoadMore.addEventListener('click', () => {
      this.searchPage = this.searchPage + 1
      this.btnLoadMore.style.display = "none"
      this.performSearch()
    })
  }

  validProducts() {
    return this.products ? this.products.filter(p =>
      !p.isDeleted && p.price && p.price.currencyIsoCode == app.currency.currencyCode())
      : []
  }

  priceFormatted(amount) {
    return app.currency.currencySymbol() + " " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  async showBookDetails(bookId) {
    let book = this.products.filter((p) => p.forProduct == bookId)[0]
    this.dlgBookDetails.innerHTML = renderBookDetails(this, book)
    let bulkPricing = this.dlgBookDetails.querySelector("#bulkPricing")
    if (bulkPricing) bulkPricing.addEventListener('click', async () => {
      // fetch bulk pricing once and then cache the result
      if (bulkPricing.loaded) return;
      let prices = await app.api.getProductPrices(book.id, app.getBuyOrganisation())
      let template = document.createElement('div')
      template.innerHTML = renderBulkPricing(this, prices)
      let skeletonText = bulkPricing.querySelector("ion-skeleton-text")
      if (skeletonText) bulkPricing.removeChild(skeletonText)
      bulkPricing.appendChild(template)
      bulkPricing.loaded = true
    })
    let btnTrial = this.dlgBookDetails.querySelector("#btnTrial")
    if (btnTrial) btnTrial.addEventListener('click', () => {
      // add trial to cart
      app.cart.addProduct(book, 1, true)
      app.toast("Trial added to cart, click the cart button top-right to view the cart.")
    })
    let bundleProducts = this.dlgBookDetails.querySelectorAll("details[productIdentifier]")
    if (bundleProducts) bundleProducts.forEach(d => d.addEventListener("click", () => this.renderChildProduct(d)))
    this.dlgBookDetails.addEventListener('ok', () => this.dlgBookDetails.close())
    this.dlgBookDetails.show()
  }

  convertToDataTime(value) {
    if (!value)
      return '';

    if (value.length < 17) {
      for (var i = 0; i <= (17 - value.length); i++) {
        value = value + '0';
      }
    }

    return {
      year: value.substring(0, 4),
      month: value.substring(4, 6),
      day: value.substring(6, 8),
      hour: value.substring(8, 10),
      min: value.substring(10, 12),
      sec: value.substring(12, 14),
      millis: value.substring(14, 17),
    }
  }

  formatDateTime(updateTime) {
    let dateObject = this.convertToDataTime(updateTime);
    return `${dateObject.day}/${dateObject.month}/${dateObject.year} ${dateObject.hour}h${dateObject.min}`;
  }

  // Return a default image
  getImage(book, qualifier) {
    const bookimg = '../../images/book_cover.png';
    const featureimg = "../../images/feature_cover.png"

    let media = book.medias.filter(m => m.qualifier == qualifier)
    if (media.length > 0) {
      return app.api.getMediaUrl(media[0].ruid)
    }

    if (book.productTypeName == "Feature") {
      return featureimg;
    } else {
      return bookimg;
    }
  }

  bookIcon(type) {
    switch (type.toLowerCase().trim()) {
      case "feature": return "ico_type_feature.svg";
      case "web site permission": return "ico_type_website_permission.svg";
      case "jumptext book": return "ico_type_jumptext_book.svg";
      case "numbersense workbook": return "ico_type_numbersense_book.svg";
      default: return "ico_type_general.svg";
    }
  }
})

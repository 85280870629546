
import WebComponent from "../webcomponent.js";

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">

<style>
details {
  margin-left: 2em;
  margin-bottom: 1em;
}

summary {
  margin-left: -2em;
}
</style>

<section>
  <header>
    <h1>Frequently asked questions</h1>
  </header>
<p>
<h3>General Information</h3>

<details>
  <summary>How do I contact client support?</summary>
  See our contact details on the <a href="#contact">Contact Us</a> page
</details>

<details>
  <summary>How do I buy my workbooks from the store?</summary>
  Browse, or search, for workbooks you need and add them to the cart. On checkout you will need to register first if you are a new customer. After a successful purchase you can then add learners and assign workbooks to your classes and learners. View the video introduction <a href="https://vimeo.com/1047857283?share=copy" target="_blank">here </a>for a brief overview on how to use the store.
</details>

<h3>Account Management</h3>

<details>
  <summary>How do I register/create an account?</summary>
  Click on the "Register" link and fill out the required information to register/create a new account.
</details>


<details>
    <summary>How do I reset my password?</summary>
   Click on the "Forgotten Password" link on the login page and follow the instructions to reset your password.
</details>

<h3>Product Information</h3>

<details>
    <summary>How can I find more information about a specific product?</summary>
   Click on the product name or image to view detailed information, including descriptions and features.
</details>

<h3>Ordering and Payment</h3>

<details>
    <summary>How do I place an order?</summary>
   Browse our products, add your desired items to the cart, click the cart icon to view the items you added in your cart, adjust the quantity of your selected items then click “checkout” to complete your order.
</details>

<details>
    <summary>What payment methods do you accept?</summary>
   We accept major credit cards, debit cards, and other secure payment options listed during the checkout process.
</details>

<details>
    <summary>Can I change or cancel my order?</summary>
   Contact our client support team as soon as possible to modify or cancel your order. Please note that changes or cancellations may not be possible once the order has been processed.
</details>

<h3>Shipping and Delivery</h3>

<details>
    <summary>How can I track my order?</summary>
   You can view order progress and details in the <a href="#orders">My Orders</a> page. You can use these details to follow up on your order with our client support desk in case you do not receive your licenses instantly.
</details>

<details>
    <summary>What if my ordered licenses are delayed?</summary>
   Contact our client support team if you experience any issues with your ordered licenses. We will assist you in resolving the problem.
</details>


<h3>Refunds</h3>

<details>
    <summary>How do I request a refund?</summary>
   To request a refund, contact our client support team with your order details and reason for refund. We will issue a refund based on the results of the order inspection, which will be communicated to you.
</details>

<h3>Technical Support</h3>

<details>
    <summary>What should I do if I encounter a technical issue with the platform?</summary>
   If you experience any technical issues, please <a href="#contact">contact our support team</a>
</details>


<details>
        <summary>Are there user guides or tutorials available?</summary>
   Yes, we provide user guides and video tutorials to help you navigate and utilize our platform effectively. You can request these from our support team.
    </details>

<h3>Privacy and Security</h3>

<details>
        <summary>How do you protect my personal information?</summary>
   We use advanced security measures to protect your personal information. View our <a href="https://jumptrak.io/privacy-policy/" target="_blank">privacy policy</a> for more details.
    </details>

<details>
        <summary>Is my payment information secure?</summary>
   Yes, all payment transactions are processed through secure gateways to ensure your information is protected, and no credit card or banking details are stored on our system.
    </details>

<h3>Feedback and Reviews</h3>

<details>
        <summary>How can I provide feedback or leave a review?</summary>
   We value your feedback. You can contact our client support team to share your thoughts and suggestions.
    </details>


  </p>
</section>
<p>&nbsp;</p>
`

customElements.define('page-faq', class extends WebComponent {
  render() {
    this.shadow.innerHTML = template(this)
  }
})
